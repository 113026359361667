import { render, staticRenderFns } from "./HomepageOverviewIntro.vue?vue&type=template&id=dc429ee0&scoped=true&"
import script from "./HomepageOverviewIntro.vue?vue&type=script&lang=js&"
export * from "./HomepageOverviewIntro.vue?vue&type=script&lang=js&"
import style0 from "./HomepageOverviewIntro.vue?vue&type=style&index=0&id=dc429ee0&prod&scoped=true&lang=css&"
import style1 from "./HomepageOverviewIntro.vue?vue&type=style&index=1&id=dc429ee0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc429ee0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BasicWaves: require('/usr/src/app/components/BasicWaves.vue').default})
