
import TextBlockMoreIcons from '~/components/ui/TextBlockMoreIcons.vue'
import TrakkenCard from '~/components/ui/TrakkenCard.vue'
import BasicWaves from '~/components/BasicWaves.vue'
export default {
  props: {
    overviewDescription: {
      type: Array,
      required: true
    },
    color_primary: {
      type: Array,
      required: true
    },
    color_secondary: {
      type: Array,
      required: true
    }
  },
  components: {
    TextBlockMoreIcons,
    TrakkenCard,
    BasicWaves
  },
  methods: {
    onSetAnimationClass({ going }) {
      if (going === this.$waypointMap.GOING_IN && window.innerWidth > 992) {
        this.animationClass = 'animate__animated animate__fadeInUp'
      }
    }
  },
  data() {
    return {
      animationClass: '',
      intersectionOptions: {
        root: null,
        rootMargin: '0px 0px 0px 0px',
        threshold: [0.75, 0.75]
      }
    }
  },
  computed: {
    // cssVars() {
    //   return {
    //     '--bg-color': '#fff',
    //     '--svg-hover-color': this.color_primary[0].color
    //   }
    // },
    svgFillColor() {
      return this.color_primary[0].color
    }
  }
}
